import { ChangeEvent, FC, useCallback, useMemo, KeyboardEvent } from 'react';
import { Menu } from 'react-feather';
import { matchPath, useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format, formatDistance } from 'date-fns';
import { Skeleton, useMediaQuery, useTheme } from '@mui/material';
import QuestionIconSVG from '../../../assets/icons/QuestionIcon';
import LeftArrowIcon from '../../../assets/icons/LeftArrowIcon';
import Tooltip from '../tooltip/Tooltip';
import DateIcon from '../../../assets/icons/DateIcon';
import { useNavigationStateValue } from '../../../contexts/NavigationContext';
import UserHeaderControl from '../user-header-control/UserHeaderControl';
import { useUserAuthStateValue } from '../../../contexts/UserAuthContext';
import { isInTeams } from '../../../utils/isInTeams';
import {
    RootBox,
    ActionBox,
    BackIconBox,
    BackNavigationBox,
    BackNavigationPageNameSpan,
    DateLabel,
    HeaderBox,
    LastUpdatedBox,
    PageName,
    RootInnerBox,
    RootInnerContent,
    SidemenuControlBox,
    StyledQuestionIconButton,
    TabsBox,
    TabLinkItemBox,
    StyledFeedbackIconButton,
    BackNavigationControlBox,
    EngagementPointsBox,
    EngagementPointsTypography,
    EngagementPointValueTypography,
    EngagementPointLabelTypography,
    RootInnerContentMdDown,
    UserRow,
    TabsRow,
    SearchRow,
    SearchInputWrapper,
    StyledSearchInput
    // StyledBadge,
    // StyledBellIconButton,
} from './Style';
import { ITab, useTabsStateValue } from '../../../contexts/TabsContext';
import ClipboardIcon from '../../../assets/icons/ClipboardIcon';
import { routes } from '../../../pages/routes';
import { useCrumbsStateValue } from '../../../contexts/CrumbsContext';
import usePageHeaderConfig from '../../../hooks/usePageHeaderConfig';
import useWizardConfig from '../../../hooks/useWizardConfig';
import EngagementPointIcon from '../../../assets/icons/EngagementPointIcon';
import { accessibilityEnterKeyCallback } from '../../../utils/accessibilityUtils';
import { useAppInsightsStateValue } from '../../../contexts/AppInsightsContext';
import { getPageNameByUrl } from '../../../utils/getPageNameByUrl';
// import { SearchAccounts } from 'partner-portal';
// import { Bell } from 'react-feather';
// import { useNotificationStateValue } from '../../../contexts/NotificationContext';
// import NotificationsPanel from '../../notifications/notifications-panel/NotificationsPanel';

interface IProps {
    onSearchInputValueChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    searchInputValue?: string;
    notificationCount?: number;
    placeholder?: string;
    isPartnerPortalMode?: boolean;
}

const PageHeader: FC<IProps> = ({
    onSearchInputValueChange,
    searchInputValue,
    placeholder,
    isPartnerPortalMode = false
}) => {
    const navigate = useNavigate();
    const { setShowNav, showNav } = useNavigationStateValue();
    const { t } = useTranslation();
    const { currentUserData, sendContentUsageRequest } = useUserAuthStateValue();
    const isTeams = isInTeams();
    const location = useLocation();
    const theme = useTheme();
    const { crumbs, appendCrumb, removeLatestCrumb, replaceLatestCrumb, startNewCrumbs } = useCrumbsStateValue();
    const { tabs, activeTab, pageName } = useTabsStateValue();
    const { config: pageHeaderConfig } = usePageHeaderConfig();
    const { startTour, config } = useWizardConfig();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const [searchParams] = useSearchParams();
    const { sendOpenHelpWizardEvent } = useAppInsightsStateValue();

    const navigateToBackPage = () => {
        const crumb = crumbs[crumbs.length - 2];
        const lastCrumb = crumbs[crumbs.length - 1];
        navigate(crumb.pathname);
        if (lastCrumb.callback) lastCrumb.callback();
        removeLatestCrumb();
    };

    const sendFeedback = () => {
        appendCrumb({
            name: t('common.feedback'),
            pathname: routes.FEEDBACK
        });
        navigate(routes.FEEDBACK, { state: { from: location.pathname } });
    };

    const openSidemenu = () => {
        setShowNav(true);
    };

    const feedbackButtonMemo = useMemo(() => {
        const isAlreadyOnFeedbackPage = location.pathname === routes.FEEDBACK;
        return (
            <Tooltip title={isAlreadyOnFeedbackPage ? '' : t('tooltips.sendFeedback')}>
                <StyledFeedbackIconButton
                    isActive={isAlreadyOnFeedbackPage}
                    onClick={isAlreadyOnFeedbackPage ? undefined : sendFeedback}
                    aria-label='Send Feedback'
                >
                    <ClipboardIcon
                        strokeColor={isAlreadyOnFeedbackPage ? theme.palette.primary.main : undefined}
                        fillColor={isAlreadyOnFeedbackPage ? theme.palette.primary.light : undefined}
                    />
                </StyledFeedbackIconButton>
            </Tooltip>
        );
    }, [location]);

    const scoredDateMemo = useMemo(() => {
        if (currentUserData?.lastScoredDate && currentUserData?.nextScoredDate) {
            return `Your progress updated ${format(
                currentUserData.lastScoredDate,
                'MMM do, yyyy'
            )}. Next update in ${formatDistance(currentUserData?.nextScoredDate, new Date(new Date().toDateString()))}`;
        } else if (currentUserData?.nextScoredDate) {
            return `Next progress update in ${formatDistance(
                currentUserData?.nextScoredDate,
                new Date(new Date().toDateString())
            )}`;
        }
        return <Skeleton width='400px' />;
    }, [currentUserData]);

    const onTabItemClick = useCallback(
        (tab: ITab) => {
            const pathname = typeof tab.path === 'string' ? tab.path : tab.path[0];
            //workaround
            if (
                tab.path === routes.INSIGHTS_PERSONAL ||
                tab.path === routes.INSIGHTS_ORGANIZATIONAL ||
                tab.path === routes.ADMIN_LICENSES ||
                tab.path === routes.ADMIN_ROLES
            ) {
                startNewCrumbs(
                    {
                        name: pageHeaderConfig.pageName,
                        pathname: tab.children && tab.children?.length > 0 ? (tab.children[0].path as string) : pathname
                    },
                    true
                );
            } else {
                replaceLatestCrumb({
                    name: pageHeaderConfig.pageName,
                    pathname: pathname
                });
            }
            if (!tab.children) navigate(pathname);
            else navigate(tab.children[0].path as string);
        },
        [pageHeaderConfig]
    );

    const onTabItemNavigationKeyDown = useCallback((e: KeyboardEvent<HTMLDivElement>, tab: ITab) => {
        if (e.key === 'Enter') {
            onTabItemClick(tab);
            e.stopPropagation();
        }
    }, []);

    const engagementPointsMemo = useMemo(() => {
        const isShowcasePage =
            matchPath(routes.SHOWCASE_HOME, location.pathname) ||
            matchPath(routes.ACHIEVEMENTS_ALL_BY_STATE, location.pathname) ||
            matchPath(routes.ACHIEVEMENT_DETAIL, location.pathname) ||
            matchPath(routes.BADGES_ALL_BY_CATEGORY, location.pathname);
        if (
            isShowcasePage &&
            currentUserData?.engagementPoints !== undefined &&
            currentUserData?.engagementPoints >= 0
        ) {
            return (
                <EngagementPointsBox id='engagement-points'>
                    <EngagementPointIcon />
                    <EngagementPointsTypography variant='subtitle1'>
                        {!isMdDown && (
                            <EngagementPointLabelTypography variant='subtitle1'>
                                Engagement Points:
                            </EngagementPointLabelTypography>
                        )}
                        <EngagementPointValueTypography variant='subtitle1'>
                            {currentUserData?.engagementPoints.toLocaleString()}
                        </EngagementPointValueTypography>
                    </EngagementPointsTypography>
                </EngagementPointsBox>
            );
        }
        return null;
    }, [location, isMdDown]);

    const crumbsMemo = useMemo(() => {
        const inTeams = isInTeams();
        const memo =
            (crumbs &&
                (crumbs.length > 2 ||
                    matchPath(location.pathname, routes.FEEDBACK) ||
                    searchParams.get('behavior') ||
                    (crumbs.length > 1 && crumbs[0].pathname !== routes.HOME && isInTeams()))) ||
            crumbs[crumbs.length - 1]?.isDirectLink ? (
                <>
                    <BackNavigationControlBox
                        tabIndex={0}
                        onKeyDown={(e: KeyboardEvent<any>) => accessibilityEnterKeyCallback(e, navigateToBackPage)}
                        onClick={navigateToBackPage}
                    >
                        <BackIconBox>
                            <LeftArrowIcon />{' '}
                        </BackIconBox>
                        <BackNavigationPageNameSpan variant='subtitle1'>
                            <>
                                {inTeams && crumbs[crumbs.length - 2].pathname !== routes.HOME
                                    ? `Back to ${crumbs[crumbs.length - 2].name}`
                                    : ''}
                                {!inTeams && crumbs[crumbs.length - 2]
                                    ? crumbs[crumbs.length - 2].name
                                        ? `Back to ${crumbs[crumbs.length - 2].name}`
                                        : 'Back'
                                    : ''}
                            </>
                        </BackNavigationPageNameSpan>
                    </BackNavigationControlBox>

                    {pageHeaderConfig?.pageName &&
                        tabs.length < 1 &&
                        !(crumbs && crumbs[crumbs.length - 1]?.isDirectLink) && (
                            <PageName variant='h6'>{pageHeaderConfig.pageName}</PageName>
                        )}
                </>
            ) : (
                <>
                    {pageName ? (
                        <PageName variant='h6'>{pageName}</PageName>
                    ) : (
                        pageHeaderConfig.pageName && <PageName variant='h6'>{pageHeaderConfig.pageName}</PageName>
                    )}
                </>
            );
        return memo;
    }, [crumbs, pageName, pageHeaderConfig]);

    const tabsMemo = useMemo(() => {
        return (
            <TabsBox>
                {tabs &&
                    tabs.map((tab) => {
                        if (tabs.length > 1 || (tabs.length === 1 && tab.alwaysVisible)) {
                            return (
                                <TabLinkItemBox
                                    key={tab.id}
                                    onClick={() => {
                                        onTabItemClick(tab);
                                    }}
                                    isActive={tab.id === activeTab?.id}
                                    tabIndex={0}
                                    onKeyDown={(e) => onTabItemNavigationKeyDown(e, tab)}
                                >
                                    {tab.title}
                                </TabLinkItemBox>
                            );
                        }
                        return <></>;
                    })}
            </TabsBox>
        );
    }, [tabs, activeTab]);

    const searchMemo = useMemo(() => {
        if (isPartnerPortalMode) {
            return <SearchInputWrapper className='search-box'>{/* <SearchAccounts /> */}</SearchInputWrapper>;
        }
        if (pageHeaderConfig.isSearchVisible && onSearchInputValueChange)
            return (
                <SearchInputWrapper className='search-box'>
                    <StyledSearchInput
                        placeholder={pageHeaderConfig?.searchPlaceholder}
                        value={searchInputValue || ''}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (onSearchInputValueChange) onSearchInputValueChange(e);
                        }}
                        ariaLabel='Search Item'
                    />
                </SearchInputWrapper>
            );
        return <></>;
    }, [pageHeaderConfig, onSearchInputValueChange, searchInputValue]);

    const headerContentMemo = useMemo(() => {
        const isSearchVisible = pageHeaderConfig.isSearchVisible && onSearchInputValueChange;
        const wizardJsx = (
            <Tooltip title={!config.wizardSteps ? t('common.notAvailableForCurrentPage') : t('common.help')}>
                <StyledQuestionIconButton
                    disabled={!config.wizardSteps}
                    className='wizard-help'
                    onClick={() => {
                        if (matchPath(location.pathname, routes.HOME) || matchPath(location.pathname, '/')) {
                            sendContentUsageRequest();
                        } else {
                            sendOpenHelpWizardEvent({
                                pageUrl: location.pathname,
                                pageName: getPageNameByUrl(location.pathname) || 'Unknown'
                            });
                        }
                        startTour();
                    }}
                    aria-label='Open Help'
                >
                    <QuestionIconSVG isDisabled={!config.wizardSteps} />
                </StyledQuestionIconButton>
            </Tooltip>
        );
        if (isMdDown) {
            return (
                <RootInnerContentMdDown
                    isTabsVisible={!!tabs && tabs?.length > 0}
                    isThirdRowVisible={!!isSearchVisible}
                >
                    <UserRow>
                        <BackNavigationBox id='header-back-navigation-box' isSidemenuVisible={showNav}>
                            {crumbsMemo}
                        </BackNavigationBox>
                        {engagementPointsMemo}
                        {feedbackButtonMemo}
                        {wizardJsx}
                        <UserHeaderControl />
                    </UserRow>
                    <TabsRow>{tabsMemo}</TabsRow>
                    {isSearchVisible && <SearchRow>{searchMemo}</SearchRow>}
                </RootInnerContentMdDown>
            );
        }
        return (
            <RootInnerContent isPartnerPortalMode={isPartnerPortalMode}>
                <BackNavigationBox isSidemenuVisible={showNav || !isInTeams} id='header-back-navigation-box'>
                    {crumbsMemo}
                </BackNavigationBox>
                {tabsMemo}
                <ActionBox>
                    {searchMemo}
                    {engagementPointsMemo}
                    {feedbackButtonMemo}
                    {wizardJsx}
                    <UserHeaderControl />
                </ActionBox>
            </RootInnerContent>
        );
    }, [tabsMemo, searchMemo, engagementPointsMemo, feedbackButtonMemo, crumbsMemo, config, showNav]);

    return (
        <>
            <RootBox applyPadding={!pageHeaderConfig.isLastUpdatedInfoVisible} isOpen={showNav && !isTeams}>
                <HeaderBox isSearchVisible={!!(pageHeaderConfig.isSearchVisible && onSearchInputValueChange)}>
                    {!showNav && !isTeams && (
                        <SidemenuControlBox onClick={openSidemenu}>
                            <Menu />
                        </SidemenuControlBox>
                    )}
                    <RootInnerBox isSidemenuOpen={showNav}>{headerContentMemo}</RootInnerBox>
                </HeaderBox>
                {pageHeaderConfig.isLastUpdatedInfoVisible && (
                    <LastUpdatedBox id='scoring-update-info'>
                        <DateIcon />
                        <DateLabel variant='overline'>{scoredDateMemo}</DateLabel>
                    </LastUpdatedBox>
                )}
            </RootBox>
        </>
    );
};

export default PageHeader;
